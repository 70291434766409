<template>
  <div>
    <div class="flex-container d-flex flex-column">
      <p
        tabindex="0"
        class="text-center intro-text text-caption grey--text mt-3 mb-1 mt-sm-8"
      >
      Complete the form to create a standing order with your mobile or online banking.
      </p>
      <p
        tabindex="0"
        class="text-center intro-text text-caption grey--text mt-0"
      >
      Your security details will not be shared with us.
      </p>

      <v-form class="mt-5">
        <v-text-field
          :suffix="paymentBreakdown.Description"
          :value="inputAmount !== null ? this.inputAmount : 'GBP '"
          :aria-label="paymentBreakdown !== null ? `Regular amount: GBP${paymentBreakdown.Amount}, description: ${paymentBreakdown.Description}` : ''"
          outlined
          v-model="inputAmount"
          :readonly="isGeneric === false"
        ></v-text-field>

        <div class="mt-n6 mb-6">
          <p class="my-0 text-caption grey--text">
            Payment to:
            <strong>{{ $store.state.paymentDetails.BankAccountName }}</strong>
          </p>
          <p class="my-0 text-caption grey--text">
            Ref: <strong>{{ $store.state.requestID }}</strong>
          </p>
        </div>

        <v-select
          :value="paymentFrequency !== 'OneOff' ? paymentFrequency : null"
          label="Frequency"
          outlined
          v-model="paymentFrequency"
          :items="possibleFrequencies"
          :readonly="isGeneric === false"
        ></v-select>

        <v-dialog
          ref="dialog1"
          v-model="startDateModal"
          :return-value.sync="startDate"
          persistent
          width="300px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class=""
              :value="formattedStartDate"
              label="First payment date"
              :error-messages="startDateErrorMessage"
              append-icon="mdi-calendar-cursor"
              :aria-label="`First payment date ${startDate}`"
              readonly
              outlined
              v-bind="attrs"
              v-on="on"
              @keydown.enter="startDateModal = true"
            ></v-text-field>
          </template>
          <v-date-picker 
            first-day-of-week="1"
            v-model="startDate" 
            :allowed-dates="allowedStartDates"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="startDateModal = false"
              @keyup.enter.stop="startDateModal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog1.save(startDate)"
              @keyup.enter.stop="$refs.dialog1.save(startDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>

        <div class="px-0 mt-n5 d-flex align-center justify-space-between">
          <span tabindex="0" class=""
            >Recurring payments until further notice:
            <span v-if="recurringPayments" class="blue--text">On</span>
            <span v-else class="red--text">Off</span>
          </span>
          <v-switch
            aria-label="Toggle recurring payments"
            color="blue"
            class="mr-n3"
            v-model="recurringPayments"
            inset
          ></v-switch>
        </div>

        <v-dialog
          ref="dialog2"
          v-model="endDateModal"
          :return-value.sync="endDate"
          persistent
          width="300px"
        >
          <template v-slot:activator="{ on, attrs }">
            <transition name="slide-in-out" mode="out-in">
              <v-text-field
                v-show="!recurringPayments"
                :value="formattedEndDate"
                :error-messages="endDateErrorMessage"
                label="Final payment date"
                append-icon="mdi-calendar-cursor"
                :aria-label="`First payment date ${endDate}`"
                readonly
                outlined
                v-bind="attrs"
                v-on="on"
                @keydown.enter="endDateModal = true"
              ></v-text-field>
            </transition>
          </template>
          <v-date-picker
            first-day-of-week="1"
            v-model="endDate" 
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="endDateModal = false"
              @keyup.enter.stop="endDateModal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog2.save(endDate)"
              @keyup.enter.stop="$refs.dialog2.save(endDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-form>

      <v-btn
        @click="setDetailAndContinue"
        :disabled="startDateError || endDateError"
        class="mb-3 mt-5"
        x-large
        depressed
        color="primary"
        block
      >
        continue
      </v-btn>
    </div>
  </div>
</template>

<script>
import moment from 'moment/src/moment'

export default {
  name: 'standingOrderDetails',
  created() {
    this.isGeneric = this.$store.state.paymentDetails.IsGenericLink;
    if(!this.isGeneric){
      //  Set various local values
      const paymentFrequency = this.$store.state.paymentDetails.Request.Frequency.toLowerCase()
      this.paymentFrequency = paymentFrequency.charAt(0).toUpperCase() + paymentFrequency.slice(1)

      this.paymentBreakdown = this.$store.state.paymentDetails.Request.Breakdown[0]
      this.startDate = this.$store.state.paymentDetails.Request.InitialPayment.substr(0, 10)
      if (this.$store.state.paymentDetails.Request.FinalPayment) this.endDate = this.$store.state.paymentDetails.Request.FinalPayment.substr(0, 10)
      if (!this.endDate) this.recurringPayments = true
      else this.recurringPayments = false
    }
    if(this.paymentBreakdown !== undefined && this.paymentBreakdown !== ''){
      this.inputAmount =  `GBP ${this.paymentBreakdown.Amount.toFixed(2)}`;
    }
    // Setting control date for startDate validation
    const twoDaysInFuture = moment().add(3, 'days').format()
    this.controlStartDate = twoDaysInFuture.substr(0, 10)
    console.log(this.paymentBreakdown.Amount);
  },
  data() {
    return {
      inputAmount: null,
      paymentBreakdown: '',
      paymentFrequency: '',
      recurringPayments: '',
      possibleFrequencies: ["Weekly", "Monthly"],
      startDate: '',
      endDate: '',
      todaysDate: new Date().toISOString().substr(0, 10),
      controlStartDate: '', // 3 days in the future
      startDateModal: false,
      endDateModal: false,
      isGeneric: false

    }
  },
  methods: {
    allowedStartDates(val) {
      let dayNumber = moment(val).day()
      if(dayNumber > 0 && dayNumber <= 5) {
        return val
      }
    },
    setDetailAndContinue() {
      let totalToSubmit ="";
      if(this.isGeneric === false){
        totalToSubmit = this.paymentBreakdown.Amount;
      }
      else{
        totalToSubmit = this.inputAmount;
      }
      const submissionDetails = {
        startDate: `${this.startDate}T00:00:00`,
        endDate: `${this.endDate}T00:00:00`,
        total: totalToSubmit
      }
      this.$store.commit('SET_PAYMENT_DETAILS_TO_SUBMIT', submissionDetails)
      this.$router.push({ name: 'StandingOrderSelectBank' })
    }
  },
  computed: {
    formattedStartDate() {
      return this.startDate ? moment(this.startDate).format('DD/MM/YYYY') : ''
    },
    formattedEndDate() {
      return this.endDate ? moment(this.endDate).format('DD/MM/YYYY') : ''
    },
    startDateErrorMessage() {
      if (this.startDate <= this.controlStartDate) return 'Date must be at least 3 days from today.'
      return ''
    },
    startDateError() {
      if (this.startDate <= this.controlStartDate) return true
      return false
    },
    startEndDateDifference() {
      if (this.endDate) {
        const startDate = moment(this.startDate)
        const endDate = moment(this.endDate)
        const daysApart = endDate.diff(startDate, 'days')
        return daysApart
      }
      return 100000000
    },
    endDateErrorMessage() {
      if (this.paymentFrequency === 'Monthly') {
        if (this.startEndDateDifference < 30) return 'Start and end dates must be at least a month apart'
      }
      if (this.paymentFrequency === 'Weekly') {
        if (this.startEndDateDifference < 7) return 'Start and end dates must be at least a week apart'
      }
      return ''
    },
    endDateError() {
       if (this.paymentFrequency === 'Monthly') {
        if (this.startEndDateDifference < 30) return true
      }
      return false
    }
  },
  watch: {
    recurringPayments(newValue) {
      if (newValue) this.endDate = ''
    }
  }
}
</script>

<style scoped>
.intro-text {
  line-height: 1.3;
}
</style>
